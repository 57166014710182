import { initializeApp } from "firebase/app";
import {
  getAuth,
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
} from "firebase/auth";
import { getFirestore, collection } from "firebase/firestore"; // Importamos collection desde firebase/firestore
import {
  getDatabase as getRealtimeDatabase,
  ref,
  set,
  push,
  get,
  child,
  remove,
  equalTo,
  query,
  update,
  orderByChild,
} from "firebase/database";
import { onValue } from "firebase/database";
import {
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

// Conexion base de datos en Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCItgOCQ9J3oce4_WqQGGCntIt90K7A7WQ",
  authDomain: "chanchito-57264.firebaseapp.com",
  databaseURL: "https://chanchito-57264-default-rtdb.firebaseio.com",
  projectId: "chanchito-57264",
  storageBucket: "chanchito-57264.appspot.com",
  messagingSenderId: "297929432202",
  appId: "1:297929432202:web:e0b6e8074001ca4a812173",
  measurementId: "G-VHVL95H5TN",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const database = getRealtimeDatabase(app);
const db = getFirestore(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

export {
  app as firebaseApp,
  auth,
  updateEmail,
  updatePassword,
  database,
  ref,
  set,
  push,
  onValue,
  db,
  get,
  child,
  remove,
  collection,
  getFirestore,
  reauthenticateWithCredential,
  equalTo,
  query,
  update,
  orderByChild,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
};
