import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import CustomNavbar from "./Navbar.js";
import { database, ref, push, set, get } from "./firebaseConfig";
import "firebase/compat/auth";
import "firebase/compat/database";
import { auth } from "./firebaseConfig";

function ConsultasSolicitud() {
  const [nombrePaciente, setNombrePaciente] = useState("");
  const [especiePaciente, setEspeciePaciente] = useState("Elegir");
  const [razaPaciente, setRazaPaciente] = useState("");
  const [edadPaciente, setEdadPaciente] = useState("");
  const [unidadEdad, setUnidadEdad] = useState("Años");
  const [pesoPaciente, setPesoPaciente] = useState("");
  const [notasPaciente, setNotasPaciente] = useState("");
  const [usuarioId, setUsuarioId] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUsuarioId(user.uid);
      } else {
        window.location.href = "/consultas";
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (especiePaciente === "Elegir") {
      alert("Por favor elija una especie válida.");
      return;
    }
    if (pesoPaciente <= 0 || isNaN(pesoPaciente) || pesoPaciente > 200) {
      alert(
        "El peso del paciente debe ser un número mayor que 0 y menor que 200 kg."
      );
      return;
    }
    if (edadPaciente <= 0 || isNaN(edadPaciente)) {
      alert("La edad del paciente debe ser un número mayor que 0.");
      return;
    }
    if (unidadEdad === "Años" && edadPaciente > 30) {
      alert(
        "La edad del paciente no parece ser real. Por favor, verifica los datos."
      );
      return;
    }
    if (unidadEdad === "Meses" && edadPaciente > 360) {
      alert(
        "La edad del paciente no parece ser real. Por favor, verifica los datos."
      );
      return;
    }
    if (nombrePaciente.length < 3) {
      alert("El nombre del paciente debe tener al menos 3 caracteres.");
      return;
    }
    if (razaPaciente.length < 3) {
      alert("La raza del paciente debe tener al menos 3 caracteres.");
      return;
    }

    const pesoPacienteConUnidad = `${pesoPaciente}kg`;

    const edadPacienteConUnidad = `${edadPaciente} ${unidadEdad}`;

    const nuevoPacienteData = {
      nombre: nombrePaciente,
      notas: notasPaciente,
      especie: especiePaciente,
      raza: razaPaciente,
      edad: edadPacienteConUnidad,
      peso: pesoPacienteConUnidad,
    };

    const nuevoPacienteRef = ref(database, `/paciente`);
    const nuevoPacientePushedRef = push(nuevoPacienteRef);
    const pacienteId = nuevoPacientePushedRef.key;

    set(nuevoPacientePushedRef, nuevoPacienteData).then(() => {
      const nuevaSolicitudRef = ref(database, `/solicitudesconsulta`);
      const nuevaSolicitudPushedRef = push(nuevaSolicitudRef);
      const solicitudId = nuevaSolicitudPushedRef.key;

      const nuevaSolicitudData = {
        id: solicitudId,
        estado: "pendiente",
        notas: notasPaciente,
        paciente_id: pacienteId,
        usuario_id: usuarioId,
      };

      set(nuevaSolicitudPushedRef, nuevaSolicitudData)
        .then(() => {
          alert("Solicitud de consulta creada exitosamente");
          window.location.href = "/consultas";
        })
        .catch((error) => {
          alert(
            "Hubo un error al crear la solicitud de consulta. Por favor, inténtalo de nuevo más tarde."
          );
        });
    });
  };

  return (
    <div className="ConsultasSolicitud">
      <CustomNavbar />
      <h2>Nueva Solicitud de Consulta</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="nombrePaciente">
          <Form.Label>Nombre del paciente:</Form.Label>
          <Form.Control
            type="text"
            value={nombrePaciente}
            onChange={(e) => setNombrePaciente(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="especiePaciente">
          <Form.Label>Especie:</Form.Label>
          <Form.Control
            as="select"
            value={especiePaciente}
            onChange={(e) => setEspeciePaciente(e.target.value)}
            required
          >
            <option value="Elegir">Elegir</option>
            <option value="Perro">Perro</option>
            <option value="Gato">Gato</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="razaPaciente">
          <Form.Label>Raza:</Form.Label>
          <Form.Control
            type="text"
            value={razaPaciente}
            onChange={(e) => setRazaPaciente(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="edadPaciente">
          <Form.Label>Edad:</Form.Label>
          <Form.Control
            type="number"
            value={edadPaciente}
            onChange={(e) => setEdadPaciente(e.target.value)}
            required
          />
          <Form.Control
            as="select"
            value={unidadEdad}
            onChange={(e) => setUnidadEdad(e.target.value)}
            required
          >
            <option value="Años">Años</option>
            <option value="Meses">Meses</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="pesoPaciente">
          <Form.Label>Peso (kg):</Form.Label>
          <Form.Control
            type="number"
            value={pesoPaciente}
            onChange={(e) => setPesoPaciente(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="notasPaciente">
          <Form.Label>Notas:</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={notasPaciente}
            onChange={(e) => setNotasPaciente(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Crear Solicitud
        </Button>
      </Form>
    </div>
  );
}

export default ConsultasSolicitud;
