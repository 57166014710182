import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";
import pigLogoLock from "./img/logo-lock.png";
import "./css/RecuperarPassword.css";

function RecuperarPassword() {
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const auth = getAuth();

  const handleSubmitEmail = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMensaje(
        "Se ha enviado un correo electrónico de restablecimiento de contraseña a " +
          email
      );
    } catch (error) {
      setMensaje(
        "Error al enviar el correo electrónico de restablecimiento de contraseña: " +
          error.message
      );
    }
  };

  return (
    <>
      <div className="login-container login-cont-lock complete">
        <img src={pigLogoLock} alt="Logo de cerdito" className="logoLock" />
        <form className="login-form" onSubmit={handleSubmitEmail}>
          <label htmlFor="email" className="labelEmail">
            Correo Electrónico
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="rounded-input lock-input"
            placeholder="Ingresa tu email"
          />
          <div className="button-container">
            <button type="submit" className="login-button lock-button-login">
              Restablecer
            </button>
            <p className="register-link">
              <Link to="/" classNAme="link-back">
                Volver al inicio de sesión
              </Link>
            </p>
          </div>
        </form>
        {mensaje && <p>{mensaje}</p>}
      </div>
    </>
  );
}

export default RecuperarPassword;
