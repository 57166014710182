import React, { useState } from "react";
import { Navbar, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCog, FaUser } from "react-icons/fa";
import "./css/Navbar.css";

function CustomNavbar() {
  return (
    <Navbar
      bg="transparent"
      variant="transparent"
      style={{
        top: 0,
        width: "100%",
        zIndex: 100,
      }}
    >
      <div className="App-navbar-buttons">
        <Link to="/settings">
          <Button variant="transparent" className="App-navbar-button">
            <FaCog /> {/* Settings */}
          </Button>
        </Link>
      </div>
      <Navbar.Brand className="App-navbar-brand">
        <Link to="/login" className="custom-link">
          <span className="App-title">CHANCHITO</span>
        </Link>
      </Navbar.Brand>
      <div className="App-navbar-buttons">
        <Link to="/profile">
          <Button variant="transparent" className="App-navbar-button">
            <FaUser /> {/* Perfil */}
          </Button>
        </Link>
      </div>
    </Navbar>
  );
}

export default CustomNavbar;
