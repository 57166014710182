import React, { useState, useEffect } from "react";
import "./css/LoginPage.css";
import pigLogo from "./img/pig-logo.png";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { useUser } from "./UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "font-awesome/css/font-awesome.min.css";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setCurrentUser } = useUser();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        localStorage.setItem("userId", user.uid);
        window.location.href = `/login`;
      }
    });
    return () => unsubscribe();
  }, [setCurrentUser]);

  const handleLogin = async () => {
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      handleAuthError(error);
    }
  };

  const handleAuthError = (error) => {
    let errorMessage = "Credenciales erroneas, intente denuevo.";
    if (error.code === "auth/user-not-found") {
      errorMessage = "No existe un usuario con este correo electrónico.";
    } else if (error.code === "auth/wrong-password") {
      errorMessage = "La contraseña proporcionada es incorrecta.";
    }
    setError(errorMessage);
  };

  return (
    <>
      <div
        className="login-container"
        style={{
          position: "relative",
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
        }}
      >
        <img src={pigLogo} alt="Logo de cerdito" className="logo" />
        <form className="login-form">
          <label htmlFor="username">¡Bienvenido!</label>
          <div
            className="circle circle-large"
            style={{ position: "absolute" }}
          ></div>
          <div
            className="circle circle-small circle1"
            style={{ position: "absolute" }}
          ></div>
          <div
            className="circle circle-small circle2"
            style={{ position: "absolute" }}
          ></div>
          <div
            className="circle circle-small circle3"
            style={{ position: "absolute" }}
          ></div>

          <div className="input-icon">
            <FontAwesomeIcon
              icon={faEnvelope}
              size="lg"
              className="placeholder-icon"
            />
            <input
              type="text"
              id="username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="rounded-input email-input"
              placeholder="Correo electrónico"
            />
          </div>
          <div className="input-icon">
            <FontAwesomeIcon
              icon={faLock}
              size="lg"
              className="placeholder-icon"
            />
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="rounded-input password-input"
              placeholder="Contraseña"
            />
          </div>
          <div className="button-container">
            <Nav.Link as={Link} to="/recuperar" className="olvidaste-password">
              ¿Olvidaste tu contraseña?
            </Nav.Link>
            <div className={`error-message ${error && "show"}`}>{error}</div>
            <button
              type="button"
              onClick={handleLogin}
              className="login-button"
            >
              Iniciar sesión
            </button>
            <Nav.Link as={Link} to="/register" className="register-link">
              ¿Aún no tienes cuenta? Únete aquí
            </Nav.Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default LoginPage;
