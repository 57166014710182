import React from "react";
import "./css/SettingsPage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomNavbar from "./Navbar.js";
import { Button } from "react-bootstrap";
import { auth } from "./firebaseConfig";

function SettingsPage() {
  const handleSignOut = () => {
    auth.signOut();
    window.location.href = `/`;
  };

  return (
    <div className="App">
      <CustomNavbar />
      <header className="App-header"></header>

      <main className="App-main">
        <section className="App-section">
          <h2>Ajustes</h2>
          <Button variant="danger" onClick={handleSignOut}>
            Cerrar Sesión
          </Button>
        </section>
      </main>
    </div>
  );
}

export default SettingsPage;
