import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import CustomNavbar from "./Navbar.js";
import { Link } from "react-router-dom";
import "./css/Consultas.css";
import {
  database,
  ref,
  onValue,
  get,
  orderByChild,
  equalTo,
  query,
} from "./firebaseConfig";
import { auth } from "./firebaseConfig";

function Consultas() {
  const [solicitudesPendientes, setSolicitudesPendientes] = useState([]);
  const [consultasEnCurso, setConsultasEnCurso] = useState({});
  const [rolUsuario, setRolUsuario] = useState(null);
  const [usuarioId, setUsuarioId] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const usuarioId = user.uid;
        setUsuarioId(usuarioId);
        const usuarioRef = ref(database, `/usuario/${usuarioId}`);
        onValue(usuarioRef, (snapshot) => {
          const usuarioData = snapshot.val();
          if (usuarioData && usuarioData.rol_id !== undefined) {
            setRolUsuario(usuarioData.rol_id);
            if (usuarioData.rol_id === 1) {
              window.location.href = "/veterinario";
            } else {
              cargarSolicitudesPendientes(usuarioId);
              cargarConsultasEnCurso(usuarioId);
            }
          }
        });
      } else {
        window.location.href = "/";
      }
    });

    return () => unsubscribe();
  }, []);

  const cargarSolicitudesPendientes = async (usuarioId) => {
    try {
      const solicitudesRef = ref(database, `/solicitudesconsulta`);
      const snapshot = await get(solicitudesRef);
      if (snapshot.exists()) {
        const solicitudesData = snapshot.val();
        const solicitudesUsuario = Object.values(solicitudesData).filter(
          (solicitud) =>
            solicitud.usuario_id === usuarioId &&
            solicitud.estado === "pendiente"
        );
        if (solicitudesUsuario.length > 0) {
          const solicitudesArray = await Promise.all(
            solicitudesUsuario.map(async (solicitud) => {
              const pacienteSnapshot = await get(
                ref(database, `/paciente/${solicitud.paciente_id}`)
              );
              if (pacienteSnapshot.exists()) {
                const pacienteData = pacienteSnapshot.val();
                return {
                  id: solicitud.id,
                  nombrePaciente: pacienteData.nombre,
                  pesoPaciente: pacienteData.peso,
                  razaPaciente: pacienteData.raza,
                  especiePaciente: pacienteData.especie,
                  notasPaciente: pacienteData.notas,
                };
              }
              return null;
            })
          );
          const solicitudesConPacientes = solicitudesArray.filter(
            (solicitud) => solicitud !== null
          );
          setSolicitudesPendientes(solicitudesConPacientes);
        } else {
          setSolicitudesPendientes([]);
        }
      } else {
        setSolicitudesPendientes([]);
      }
    } catch (error) {
      setSolicitudesPendientes([]);
    }
  };

  const cargarConsultasEnCurso = async (usuarioId) => {
    try {
      const consultasRef = ref(database, `/consultasencurso`);
      const q = query(
        consultasRef,
        orderByChild("usuario_id"),
        equalTo(usuarioId)
      );
      const snapshot = await get(q);
      if (snapshot.exists()) {
        setConsultasEnCurso(snapshot.val());
      } else {
        setConsultasEnCurso({});
      }
    } catch (error) {
      setConsultasEnCurso({});
    }
  };

  const handleChat = (consultaId) => {
    if (!consultaId) {
      return;
    }

    if (!usuarioId) {
      return;
    }

    window.location.href = `/chat/${usuarioId}/${consultaId}`;
  };

  const handleHiloConsulta = (consultaId) => {
    if (!consultaId) {
      return;
    }

    window.location.href = `/foro/${consultaId}`;
  };

  return (
    <div className="Consultas container">
      <CustomNavbar />
      <div className="categoria card">
        <h2 className="card-header">Solicitudes Pendientes</h2>
        <div className="card-body">
          {solicitudesPendientes.length > 0 ? (
            solicitudesPendientes.map((solicitud) => (
              <div key={solicitud.id} className="solicitud card mb-3">
                <div className="card-body">
                  <h3 className="card-title">{solicitud.nombrePaciente}</h3>
                  <p className="card-text">
                    <strong>Raza y Especie:</strong> {solicitud.razaPaciente} (
                    {solicitud.especiePaciente})
                  </p>
                  <p className="card-text">
                    <strong>Notas:</strong> {solicitud.notasPaciente}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>No hay solicitudes pendientes.</p>
          )}
          <Button variant="primary" as={Link} to="/solicitud">
            Nueva Solicitud
          </Button>
        </div>
      </div>

      <hr className="separator my-4" />

      <div className="categoria card">
        <h2 className="card-header">Consultas en Curso</h2>
        <div className="card-body">
          {Object.keys(consultasEnCurso).length > 0 ? (
            Object.keys(consultasEnCurso).map((consultaId) => (
              <div key={consultaId} className="consulta card mb-3">
                <div className="card-body">
                  <h3 className="card-title">
                    {consultasEnCurso[consultaId].paciente_nombre}
                  </h3>
                  <p className="card-text">
                    <strong>Nombre del usuario:</strong>{" "}
                    {consultasEnCurso[consultaId].usuario_nombre_completo}
                  </p>
                  <p className="card-text">
                    <strong>Raza y Especie:</strong>{" "}
                    {consultasEnCurso[consultaId].raza_paciente} (
                    {consultasEnCurso[consultaId].especie_paciente})
                  </p>
                  <Button onClick={() => handleChat(consultaId)}>
                    Abrir Chat
                  </Button>
                  <Button
                    onClick={() => handleHiloConsulta(consultaId)}
                    style={{ marginLeft: "5px" }}
                  >
                    Ir a la consulta
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <p>No hay consultas en curso.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Consultas;
