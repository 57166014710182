import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./css/index.css";
import { MantineProvider } from "@mantine/core";
import App from "./App";
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import RecuperarPassword from "./RecuperarPassword";
import SettingsPage from "./SettingsPage";
import ProfilePage from "./ProfilePage";
import Solicitud from "./ConsultasSolicitud";
import Consultas from "./Consultas";
import Veterinario from "./Veterinario";
import Informacion from "./Informacion";
import Facturacion from "./Facturacion";
import reportWebVitals from "./reportWebVitals";
import Chat from "./Chat";
import Glosario from "./Glosario";
import Foro from "./Foro";
import HiloConsulta from "./HiloConsulta";
import Historial from "./Historial";
import { UserProvider } from "./UserContext";
import { register } from "./serviceWorkerRegistration";

import { register as registerServiceWorker } from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <UserProvider>
        <MantineProvider>
          <Routes>
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<App />} />
            <Route path="/recuperar" element={<RecuperarPassword />} />
            <Route path="/consultas" element={<Consultas />} />
            <Route path="/solicitud" element={<Solicitud />} />
            <Route path="/informacion" element={<Informacion />} />
            <Route path="/veterinario" element={<Veterinario />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/chat/:usuarioId/:consultaId" element={<Chat />} />
            <Route path="/facturacion" element={<Facturacion />} />
            <Route path="/facturacion/:consultaId" element={<Facturacion />} />
            <Route path="/glosario" element={<Glosario />} />
            <Route path="/historial" element={<Historial />} />
            <Route path="/foro" element={<Foro />} />
            <Route exact path="/foro/:consultaid" element={<HiloConsulta />} />
          </Routes>
        </MantineProvider>
      </UserProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
registerServiceWorker();
register();
