import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomNavbar from "./Navbar.js";
import "./css/Veterinario.css";
import {
  database,
  ref,
  onValue,
  get,
  push,
  set,
  remove,
  query,
  orderByChild,
  update,
  auth,
  equalTo,
} from "./firebaseConfig";
import { getAuth } from "firebase/auth";
import TratamientoMenu from "./TratamientoMenu";
import EditarTratamientoModal from "./EditarTratamientoModal";

function Veterinario() {
  const [showEditarTratamientoModal, setShowEditarTratamientoModal] =
    useState(false);
  const [tratamientoIdEditar, setTratamientoIdEditar] = useState(null);
  const [tratamientoDataEditar, setTratamientoDataEditar] = useState(null);
  const [showTratamientoMenu, setShowTratamientoMenu] = useState(false);
  const [solicitudesPendientes, setSolicitudesPendientes] = useState([]);
  const [consultasEnCurso, setConsultasEnCurso] = useState([]);
  const [consultasExpandidas, setConsultasExpandidas] = useState([]);
  const [hoveredConsulta, setHoveredConsulta] = useState(null);
  const [consultaId, setConsultaId] = useState(null);
  const [mostrarModalTratamiento, setMostrarModalTratamiento] = useState(false);
  const [showModalTratamientoExistente, setShowModalTratamientoExistente] =
    useState(false);
  const [modalVisible, setModalVisible] = useState(null);
  const [consultaConTratamientoGuardado, setConsultaConTratamientoGuardado] =
    useState(null);
  const [tratamientoId, setTratamientoId] = useState(null);
  const [tratamientoData, setTratamientoData] = useState(null);
  const [usuarioIdTratamiento, setUsuarioIdTratamiento] = useState(null);
  const [consultaIdTratamiento, setConsultaIdTratamiento] = useState(null);
  const [showCancelarConsulta, setShowCancelarConsulta] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [pin, setPin] = useState("");

  useEffect(() => {
    const solicitudesRef = ref(database, "/solicitudesconsulta");
    const consultasEnCursoRef = ref(database, "/consultasencurso");

    const getSolicitudes = () => {
      onValue(
        solicitudesRef,
        (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const solicitudesArray = Object.entries(data)
              .map(([id, solicitud]) => ({
                id,
                ...solicitud,
              }))
              .filter(
                (solicitud) =>
                  solicitud.estado !== "cancelada" &&
                  solicitud.estado !== "rechazada" &&
                  solicitud.estado !== "finalizado"
              );
            cargarDatosPacientes(solicitudesArray);
          } else {
            setSolicitudesPendientes([]);
          }
        },
        (error) => {
          setError(error);
        }
      );
    };

    const getConsultasEnCurso = () => {
      onValue(
        consultasEnCursoRef,
        (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const consultasArray = Object.entries(data)
              .map(([id, consulta]) => ({
                id,
                ...consulta,
              }))
              .filter(
                (consulta) => consulta.veterinario_id === auth.currentUser.uid
              );
            setConsultasEnCurso(consultasArray);
          } else {
            setConsultasEnCurso([]);
          }
        },
        (error) => {
          setError(error);
        }
      );
    };

    getSolicitudes();
    getConsultasEnCurso();
  }, []);

  useEffect(() => {
    const fetchTratamientoData = async () => {
      try {
        if (hoveredConsulta) {
          const consultaActual = consultasEnCurso.find(
            (consulta) => consulta.id === hoveredConsulta
          );

          if (consultaActual && consultaActual.tratamiento_id) {
            const tratamientoRef = ref(
              database,
              `/tratamientos/${consultaActual.tratamiento_id}`
            );
            const tratamientoSnapshot = await get(tratamientoRef);
            const tratamientoData = tratamientoSnapshot.val();

            if (tratamientoData) {
              setConsultaConTratamientoGuardado(consultaActual.id);
            } else {
              setConsultaConTratamientoGuardado(null);
            }
          } else {
            setConsultaConTratamientoGuardado(null);
          }
        } else {
          setConsultaConTratamientoGuardado(null);
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchTratamientoData();
  }, [hoveredConsulta, consultasEnCurso]);

  const cargarDatosPacientes = async (solicitudes) => {
    try {
      const nuevasSolicitudes = await Promise.all(
        solicitudes.map(async (solicitud) => {
          if (solicitud.paciente_id) {
            const pacienteSnapshot = await get(
              ref(database, `/paciente/${solicitud.paciente_id}`)
            );
            if (pacienteSnapshot.exists()) {
              const pacienteData = pacienteSnapshot.val();
              return {
                ...solicitud,
                nombrePaciente: pacienteData.nombre,
                razaPaciente: pacienteData.raza,
                especiePaciente: pacienteData.especie,
                notasPaciente: pacienteData.notas,
                edadPaciente: pacienteData.edad,
                pesoPaciente: pacienteData.peso,
              };
            }
          }
          return null;
        })
      );

      const solicitudesConPacientes = nuevasSolicitudes.filter(
        (solicitud) => solicitud !== null
      );
      setSolicitudesPendientes(solicitudesConPacientes);
    } catch (error) {
      setError(error);
    }
  };

  const toggleConsultaExpandida = (consultaId) => {
    if (consultasExpandidas.includes(consultaId)) {
      setConsultasExpandidas(
        consultasExpandidas.filter((id) => id !== consultaId)
      );
    } else {
      setConsultasExpandidas([...consultasExpandidas, consultaId]);
    }
  };

  const aceptarSolicitud = async (solicitud) => {
    const solicitudId = solicitud.id;
    const usuarioId = solicitud.usuario_id;

    try {
      const usuarioSnapshot = await get(ref(database, `/usuario/${usuarioId}`));
      const usuarioData = usuarioSnapshot.val();
      const nombreUsuario = usuarioData.nombre_completo;

      const pacienteSnapshot = await get(
        ref(database, `/paciente/${solicitud.paciente_id}`)
      );
      const pacienteData = pacienteSnapshot.val();

      const auth = getAuth();
      const veterinarioId = auth.currentUser.uid;

      const nuevaConsulta = {
        fecha_inicio: new Date(Date.now()).toISOString(),
        notas: solicitud.notas,
        solicitud_id: solicitudId,
        usuario_id: usuarioId,
        veterinario_id: veterinarioId,
        paciente_nombre: pacienteData.nombre,
        especie_paciente: pacienteData.especie,
        raza_paciente: pacienteData.raza,
        peso_paciente: pacienteData.peso,
        edad: pacienteData.edad,
        usuario_nombre_completo: nombreUsuario,
      };

      const consultasEnCursoRef = ref(database, "/consultasencurso");
      const nuevaConsultaRef = push(consultasEnCursoRef).key;

      if (!nuevaConsultaRef) {
        return;
      }

      await set(
        ref(database, `/consultasencurso/${nuevaConsultaRef}`),
        nuevaConsulta
      );

      const solicitudRef = ref(database, `/solicitudesconsulta/${solicitudId}`);
      await set(solicitudRef, { ...solicitud, estado: "en_curso" });
    } catch (error) {
      setError(error);
    }
  };

  const handleContinuar = async (consultaId, usuarioId) => {
    try {
      const consultaConTratamiento = consultasEnCurso.find(
        (consulta) => consulta.id === consultaId
      );
      setUsuarioIdTratamiento(usuarioId);
      setConsultaIdTratamiento(consultaId);
      if (consultaConTratamiento) {
        const tratamientoId = consultaConTratamiento.tratamiento_id;

        if (tratamientoId) {
          const tratamientoRef = ref(
            database,
            `/tratamientos/${tratamientoId}`
          );
          const tratamientoSnapshot = await get(tratamientoRef);
          const tratamientoData = tratamientoSnapshot.val();

          setTratamientoData(tratamientoData);
          setShowModalTratamientoExistente(true);
          setModalVisible("TratamientoExistente");
          setTratamientoId(tratamientoId);
        } else {
          setShowTratamientoMenu(true);
          setConsultaId(consultaId);
          setModalVisible("TratamientoMenu");
        }
      } else {
      }
    } catch (error) {}
  };

  const handleGuardarTratamiento = async () => {
    try {
      const tratamientoSnapshot = await get(
        ref(database, `/tratamientos/${tratamientoId}`)
      );
      const tratamientoData = tratamientoSnapshot.val();

      if (tratamientoData) {
        setTratamientoData(tratamientoData);
        setShowModalTratamientoExistente(true);
        setModalVisible("TratamientoExistente");
      } else {
      }
    } catch (error) {}
  };

  const handleChat = async () => {
    try {
      if (usuarioIdTratamiento && consultaIdTratamiento) {
        const chatLink = `/chat/${usuarioIdTratamiento}/${consultaIdTratamiento}`;
        window.location.href = chatLink;
      } else {
      }
    } catch (error) {}
  };

  const handleReject = async (solicitud) => {
    const solicitudId = solicitud.id;

    const motivoRechazo = window.prompt(
      "Por favor ingrese el motivo del rechazo"
    );

    if (motivoRechazo) {
      try {
        const solicitudRef = ref(
          database,
          `/solicitudesconsulta/${solicitudId}`
        );

        await update(solicitudRef, { estado: "rechazada", motivoRechazo });

        const solicitudSnapshot = await get(solicitudRef);
        const solicitudData = solicitudSnapshot.val();

        const historialRef = ref(
          database,
          `/historialconsultas/${solicitudId}`
        );

        await set(historialRef, {
          ...solicitudData,
          estado: "rechazada",
          motivoRechazo,
          fechaRechazo: new Date().toISOString(),
          solicitud_id: solicitudId,
          usuario_id: solicitudData.usuario_id,
        });
      } catch (error) {}
    } else {
    }
  };

  const handleCancelar = (consultaEnCursoId) => {
    setConsultaId(consultaEnCursoId);
    setShowCancelarConsulta(true);
  };

  const handleCancelarConfirmado = async () => {
    const user = auth.currentUser;

    const userRef = ref(database, `usuario/${user.uid}`);
    const snapshot = await get(userRef);
    const userData = snapshot.val();

    if (userData.pin !== pin) {
      setError("El PIN es incorrecto.");
      return;
    }

    const motivo = window.prompt(
      "Por favor ingresa un motivo para la cancelación de la consulta"
    );
    if (motivo === null || motivo === "") {
      setError("Debes ingresar un motivo para la cancelación de la consulta");
      return;
    }

    try {
      const consultaRef = ref(database, `consultasencurso/${consultaId}`);

      const consultaSnapshot = await get(consultaRef);
      const consultaData = consultaSnapshot.val();

      if (consultaData === null) {
        throw new Error(
          `No se encontró la consulta en curso con id ${consultaId}`
        );
      }

      const solicitud_id = consultaData.solicitud_id;

      const solicitudRef = ref(database, `solicitudesconsulta/${solicitud_id}`);
      const solicitudSnapshot = await get(solicitudRef);
      const solicitudData = solicitudSnapshot.val();

      if (solicitudData === null) {
        throw new Error(
          `No se encontró la solicitud de consulta con id ${solicitud_id}`
        );
      }

      const historialRef = ref(database, `historialconsultas/${consultaId}`);

      const usuario_id = consultaData.usuario_id;
      const usuario_nombre_completo = consultaData.usuario_nombre_completo;

      const nombrePaciente = solicitudData.nombrePaciente || "Desconocido";
      const especiePaciente = solicitudData.especiePaciente || "Desconocido";

      await remove(consultaRef);

      await update(solicitudRef, { estado: "cancelada", notas: motivo });
      await set(historialRef, {
        motivo: motivo,
        fecha: new Date().toISOString(),
        solicitud_id: solicitud_id,
        usuario_id: usuario_id,
        usuario_nombre_completo: usuario_nombre_completo,
        nombrePaciente: nombrePaciente,
        especiePaciente: especiePaciente,
        estado: "cancelada",
      });

      setShowCancelarConsulta(false);
      setPin("");
    } catch (error) {
      setError("Ocurrió un error al cancelar la consulta.");
    }
  };

  const handleCloseModalTratamientoExistente = () => {
    setShowModalTratamientoExistente(false);
  };

  const handleEditarTratamiento = (tratamientoId, tratamientoData) => {
    setTratamientoIdEditar(tratamientoId);
    setTratamientoDataEditar(tratamientoData);
    setShowEditarTratamientoModal(true);
  };

  return (
    <div className="Veterinario">
      <CustomNavbar />
      <div className="solicitudes-container">
        {error ? (
          <p>Ocurrió un error al cargar los datos: {error.message}</p>
        ) : (
          <div>
            <h2>Solicitudes Pendientes</h2>
            {solicitudesPendientes.length > 0 ? (
              solicitudesPendientes.map(
                (solicitud) =>
                  solicitud.estado !== "en_curso" && (
                    <div
                      key={solicitud.id}
                      className="solicitud"
                      onMouseEnter={() => setHoveredConsulta(solicitud.id)}
                      onMouseLeave={() => setHoveredConsulta(null)}
                      style={{
                        boxShadow:
                          hoveredConsulta === solicitud.id
                            ? "0 0 10px rgba(0, 0, 0, 0.5)"
                            : "none",
                      }}
                    >
                      <h3>{solicitud.nombrePaciente}</h3>
                      <p>
                        <strong>Raza:</strong> {solicitud.raza_paciente} <br />
                        <strong>Especie:</strong> {solicitud.especie_paciente}
                      </p>
                      <p>
                        <strong>Notas:</strong> {solicitud.notasPaciente}
                      </p>
                      <Button
                        variant="success"
                        onClick={() => aceptarSolicitud(solicitud)}
                      >
                        Aceptar
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleReject(solicitud)}
                      >
                        Rechazar
                      </Button>
                    </div>
                  )
              )
            ) : (
              <p>No hay solicitudes pendientes.</p>
            )}
          </div>
        )}
      </div>
      <hr className="separator" />
      {/* Sección de Consultas en Curso */}
      <div className="categoria">
        <h2>Consultas en Curso</h2>
        {consultasEnCurso.length > 0 ? (
          consultasEnCurso.map((consulta) => (
            <div
              key={consulta.id}
              className="consulta-container"
              onMouseEnter={() => setHoveredConsulta(consulta.id)}
              onMouseLeave={() => setHoveredConsulta(null)}
              style={{
                boxShadow:
                  hoveredConsulta === consulta.id
                    ? "0 0 10px rgba(0, 0, 0, 0.5)"
                    : "none",
              }}
            >
              {/* Cabecera de la consulta */}
              <div className="consulta-header">
                <h3>{consulta.paciente_nombre}</h3>
                <p>
                  <strong>Nombre del usuario:</strong>{" "}
                  {consulta.usuario_nombre_completo}
                </p>
                <p>
                  <strong>Raza y Especie:</strong> {consulta.raza_paciente} (
                  {consulta.especie_paciente})
                </p>
              </div>
              {/* Detalles adicionales de la consulta */}
              {consultasExpandidas.includes(consulta.id) && (
                <div className="consulta-detalles">
                  <p>Edad: {consulta.edad}</p>
                  <p>Especie: {consulta.especie_paciente}</p>
                  <p>Nombre: {consulta.paciente_nombre}</p>
                  <p>Raza: {consulta.raza_paciente}</p>
                  <p>Peso: {consulta.peso_paciente}</p>
                  <p>Notas: {consulta.notas}</p>
                  <Button
                    variant="primary"
                    onClick={() =>
                      handleContinuar(consulta.id, consulta.usuario_id)
                    }
                    style={{
                      display:
                        consultaConTratamientoGuardado === consulta.id
                          ? "none"
                          : "inline-block",
                    }}
                  >
                    Continuar
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() =>
                      handleContinuar(consulta.id, consulta.usuario_id)
                    }
                    style={{
                      display:
                        consultaConTratamientoGuardado === consulta.id
                          ? "inline-block"
                          : "none",
                    }}
                  >
                    Tratamiento
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleCancelar(consulta.id)}
                  >
                    Cancelar
                  </Button>
                </div>
              )}
              {/* Modal de TratamientoExistente */}
              <Modal
                show={showModalTratamientoExistente}
                onHide={handleCloseModalTratamientoExistente}
                centered
                size="x1"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Tratamiento Existente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Contenido del modal de tratamiento existente */}
                  {tratamientoData ? (
                    <>
                      <p>
                        <strong>Tratamiento:</strong>{" "}
                        {tratamientoData.tratamiento}
                      </p>
                      <p>
                        <strong>Medicamento:</strong>{" "}
                        {tratamientoData.medicamento}
                      </p>
                      <p>
                        <strong>Dosis:</strong> {tratamientoData.dosis}
                      </p>
                      <p>
                        <strong>Frecuencia:</strong>{" "}
                        {tratamientoData.frecuencia}
                      </p>
                    </>
                  ) : (
                    <p>No se encontraron datos del tratamiento.</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Link to={`/facturacion/${consulta.id}`}>
                    <Button variant="success">Facturación</Button>
                  </Link>
                  <Button variant="primary" onClick={handleChat}>
                    Chat
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleCloseModalTratamientoExistente}
                  >
                    Cerrar
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => handleEditarTratamiento(tratamientoId)}
                    onHide={() =>
                      handleCloseModalTratamientoExistente(
                        handleCloseModalTratamientoExistente
                      )
                    }
                  >
                    Editar
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Botón para expandir o contraer la consulta */}
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => toggleConsultaExpandida(consulta.id)}
              >
                {consultasExpandidas.includes(consulta.id) ? "-" : "+"}
              </Button>
              {/* Modal para Cancelar las Consultas */}
              <Modal
                show={showCancelarConsulta}
                onHide={() => setShowCancelarConsulta(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirmar Cancelación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    ¿Estás seguro de que quieres cancelar esta consulta? Por
                    favor, ingresa tu PIN para confirmar.
                  </p>
                  <Form>
                    <Form.Group controlId="formPin">
                      <Form.Label>PIN</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Ingresa tu PIN"
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  {error && <p className="text-danger">{error}</p>}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowCancelarConsulta(false)}
                  >
                    Cancelar
                  </Button>
                  <Button variant="danger" onClick={handleCancelarConfirmado}>
                    Confirmar Cancelación
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ))
        ) : (
          <p>No hay consultas en curso.</p>
        )}
      </div>
      <TratamientoMenu
        show={modalVisible === "TratamientoMenu"}
        onHide={() => setModalVisible(null)}
        consultaId={consultaId}
        setMostrarModalTratamiento={setMostrarModalTratamiento}
        onGuardar={handleGuardarTratamiento}
        setModalVisible={setModalVisible}
      />
      {/* Modal de edición de tratamiento */}
      <EditarTratamientoModal
        show={showEditarTratamientoModal}
        onHide={() => setShowEditarTratamientoModal(false)}
        tratamientoId={tratamientoIdEditar}
        tratamientoData={tratamientoDataEditar}
      />
    </div>
  );
}

export default Veterinario;
