import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { database, ref as databaseRef, onValue } from "./firebaseConfig";
import { ref as storageRef, getDownloadURL } from "firebase/storage"; // Importa ref y getDownloadURL desde firebase/storage
import { storage } from "./firebaseConfig";
import CustomNavbar from "./Navbar";
import "./css/Medicamentos.css";
import "./css/informacion.css";
import { Tab, Tabs, Button } from "react-bootstrap";
import piglog from "./img/logo.png";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCItgOCQ9J3oce4_WqQGGCntIt90K7A7WQ",
    authDomain: "chanchito-57264.firebaseapp.com",
    databaseURL: "https://chanchito-57264-default-rtdb.firebaseio.com",
    projectId: "chanchito-57264",
    storageBucket: "chanchito-57264.appspot.com",
    messagingSenderId: "297929432202",
    appId: "1:297929432202:web:e0b6e8074001ca4a812173",
    measurementId: "G-VHVL95H5TN",
  });
}

function Medicamentos() {
  const [medicamentos, setMedicamentos] = useState([]);

  useEffect(() => {
    const medicamentosRef = databaseRef(database, "medicamentos");

    const handleValueChange = (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (Array.isArray(data)) {
          setMedicamentos(data);
        } else {
          setMedicamentos(Object.values(data));
        }
      } else {
        setMedicamentos([]);
      }
    };

    const unsubscribe = onValue(medicamentosRef, handleValueChange);

    return () => {
      unsubscribe();
    };
  }, []);

  const handleDownload = async () => {
    try {
      const fileRef = storageRef(
        storage,
        "manual_usuario/MANUAL DE USUARIO.docx"
      );
      const url = await getDownloadURL(fileRef);
      const link = document.createElement("a");
      link.href = url;
      link.download = "MANUAL DE USUARIO.doc";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };

  return (
    <div>
      <CustomNavbar />
      <h1 className="animated-title">Informacion General</h1>
      <Tabs defaultActiveKey="info" id="uncontrolled-tab-example">
        <Tab eventKey="info" title="Información">
          <div className="info-tab">
            <img src={piglog} alt="Chanchito" />
            <p>
              Chanchito es un software veterinario diseñado para facilitar las
              consultas a domicilio. Proporciona una plataforma eficiente y
              fácil de usar para gestionar citas, mantener registros de
              pacientes y administrar medicamentos. Con Chanchito, los
              profesionales veterinarios pueden centrarse en proporcionar la
              mejor atención posible a sus pacientes, mientras que el software
              se encarga de la logística y la administración.
            </p>
            <Button
              variant="primary"
              onClick={handleDownload}
              className="manual-button"
            >
              Manual de Usuario
            </Button>
          </div>
        </Tab>
        <Tab eventKey="medicamentos" title="Medicamentos">
          <div className="product-list">
            {medicamentos.map((medicamento, index) => (
              <div key={index} className="product-card">
                <img
                  src={medicamento.image}
                  alt={medicamento.nombre}
                  className="product-image"
                />
                <div className="product-details">
                  <h2 className="product-name">{medicamento.nombre}</h2>
                  <p className="product-description">
                    {medicamento.description}
                  </p>
                  <p className="product-price">{medicamento.price}</p>
                </div>
              </div>
            ))}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Medicamentos;
