import React, { useState, useEffect } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  database,
  ref,
  onValue,
  query,
  orderByChild,
  equalTo,
  push,
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
} from "./firebaseConfig";
import "./css/Foro.css";
import CustomNavbar from "./Navbar";
import TratamientoMenu from "./TratamientoMenu";
import { getDatabase, get } from "firebase/database";
import { ref as storageRef } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

function HiloConsulta() {
  const { consultaid } = useParams();
  const [tratamientos, setTratamientos] = useState([]);
  const [respuestasUsuario, setRespuestasUsuario] = useState([]);
  const [mostrarModalTratamiento, setMostrarModalTratamiento] = useState(false);
  const [nombrePaciente, setNombrePaciente] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [respuesta, setRespuesta] = useState("");
  const [imagenes, setImagenes] = useState([]);
  const [esVeterinario, setEsVeterinario] = useState(false);
  const [subRespuestas, setSubRespuestas] = useState({});
  const [respuestaActivaId, setRespuestaActivaId] = useState(null);
  const userId = localStorage.getItem("userId");

  const fetchData = async (path, orderBy, equalToValue) => {
    try {
      const dbRef = ref(database, path);
      const dbQuery = query(
        dbRef,
        orderByChild(orderBy),
        equalTo(equalToValue)
      );
      const snapshot = await get(dbQuery);
      const data = snapshot.val();

      return data
        ? Object.entries(data).map(([id, value]) => ({ id, ...value }))
        : [];
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const treatments = await fetchData(
        "/tratamientos",
        "consultaId",
        consultaid
      );
      setTratamientos(treatments);

      const userResponses = await fetchData(
        "/respuestas_usuario",
        "consultaId",
        consultaid
      );
      setRespuestasUsuario(userResponses);

      const loadedSubRespuestas = {};
      for (const respuesta of userResponses) {
        const subRespuestasData = await fetchData(
          "/subrespuestas",
          "respuestaId",
          respuesta.id
        );
        loadedSubRespuestas[respuesta.id] = subRespuestasData;
      }
      setSubRespuestas(loadedSubRespuestas);
    };
    loadData();
  }, [consultaid]);

  useEffect(() => {
    const cargarTratamientos = () => {
      const tratamientosRef = ref(database, "/tratamientos");
      const tratamientosQuery = query(
        tratamientosRef,
        orderByChild("consultaId"),
        equalTo(consultaid)
      );

      onValue(tratamientosQuery, (snapshot) => {
        const tratamientosData = snapshot.val();

        if (tratamientosData) {
          const tratamientosList = Object.entries(tratamientosData)
            .map(([id, tratamiento]) => ({ id, ...tratamiento }))
            .sort(
              (a, b) =>
                new Date(b.fechaTratamiento) - new Date(a.fechaTratamiento)
            );

          setTratamientos(tratamientosList);
        } else {
          setTratamientos([]);
        }
      });
    };

    const respuestasUsuarioRef = ref(database, "/respuestas_usuario");
    const respuestasUsuarioQuery = query(
      respuestasUsuarioRef,
      orderByChild("consultaId"),
      equalTo(consultaid)
    );

    onValue(respuestasUsuarioQuery, (snapshot) => {
      const data = snapshot.val();
      const respuestasUsuario = [];
      for (let id in data) {
        respuestasUsuario.push({ id, ...data[id] });
      }
      setRespuestasUsuario(respuestasUsuario);
    });

    cargarTratamientos();
  }, [consultaid]);

  useEffect(() => {
    const db = getDatabase();
    const userRef = ref(db, `/usuario/${userId}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      if (userData) {
        setNombreUsuario(userData.nombre_completo);
      }
    });
  }, [userId]);

  useEffect(() => {
    const db = getDatabase();
    const userRef = ref(db, `/usuario/${userId}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      if (userData && userData.rol_id === 1) {
        setEsVeterinario(true);
      } else {
        setEsVeterinario(false);
      }
    });
  }, [userId]);

  useEffect(() => {
    const db = getDatabase();
    const consultaRef = ref(db, `/consultasencurso/${consultaid}`);
    onValue(consultaRef, (snapshot) => {
      const consultaData = snapshot.val();
      const solicitudId = consultaData.solicitud_id;

      const solicitudRef = ref(db, `/solicitudesconsulta/${solicitudId}`);
      onValue(solicitudRef, (snapshot) => {
        const solicitudData = snapshot.val();
        const pacienteId = solicitudData.paciente_id;

        const pacienteRef = ref(db, `/paciente/${pacienteId}`);
        onValue(pacienteRef, (snapshot) => {
          const pacienteData = snapshot.val();
          setNombrePaciente(pacienteData.nombre);
        });
      });
    });
  }, [consultaid]);

  const handleSelectImagen = (event) => {
    const files = Array.from(event.target.files).map((file) => ({
      id: uuidv4(),
      file: file,
    }));
    setImagenes((prev) => [...prev, ...files]);
  };

  const eliminarArchivo = (id) => {
    setImagenes((prev) => prev.filter((file) => file.id !== id));
  };

  const handleSubmit = async (event, tratamientoId) => {
    event.preventDefault();
    if (!respuesta.trim()) {
      alert("La respuesta no puede estar vacía.");
      return;
    }
    try {
      let imageUrls = [];
      if (imagenes.length > 0) {
        const storage = getStorage();
        const uploadPromises = imagenes.map((imagen) => {
          const imageRef = storageRef(storage, `images/${imagen.name}`);
          return uploadBytesResumable(imageRef, imagen).then((snapshot) =>
            getDownloadURL(snapshot.ref)
          );
        });
        imageUrls = await Promise.all(uploadPromises);
      }
      const database = getDatabase();
      const nuevaRespuesta = {
        tratamientoId: tratamientoId,
        consultaId: consultaid,
        respuesta: `${nombreUsuario}: ${respuesta}`,
        imagenes: imageUrls,
      };
      const respuestasUsuarioRef = ref(database, "/respuestas_usuario");
      await push(respuestasUsuarioRef, nuevaRespuesta);
      setRespuesta("");
      setImagenes([]);
    } catch (error) {}
  };

  return (
    <Container>
      <CustomNavbar />
      <h1>Hilo de Consulta - {nombrePaciente}</h1>
      <div className="mt-4">
        {tratamientos.map((tratamiento, index) => (
          <div key={index} className="thread">
            <div className="post">
              <h2>Tratamiento</h2>
              <Card key={tratamiento.id} className="mb-3">
                <Card.Body>
                  <Card.Title>{tratamiento.tratamiento}</Card.Title>
                  <Card.Text>
                    <strong>Medicamento:</strong> {tratamiento.medicamento}
                  </Card.Text>
                  <Card.Text>
                    <strong>Dosis:</strong> {tratamiento.dosis}
                  </Card.Text>
                  <Card.Text>
                    <strong>Frecuencia:</strong> {tratamiento.frecuencia}
                  </Card.Text>
                  <Card.Text>
                    <strong>Fecha:</strong>{" "}
                    {new Date(tratamiento.fechaTratamiento).toLocaleString()}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="post">
              <h2>Respuesta de Usuario</h2>
              {respuestasUsuario
                .filter(
                  (respuestaUsuario) =>
                    respuestaUsuario.tratamientoId === tratamiento.id
                )
                .map((respuestaUsuario, index) => (
                  <div key={index}>
                    <p>{respuestaUsuario.respuesta}</p>
                    {respuestaUsuario.imagenes &&
                      respuestaUsuario.imagenes.map((imagen, index) => (
                        <img
                          key={index}
                          src={imagen}
                          alt="Respuesta Imagen"
                          style={{
                            maxWidth: "480px",
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      ))}
                  </div>
                ))}
              <form onSubmit={(event) => handleSubmit(event, tratamiento.id)}>
                <div>
                  <label htmlFor="respuesta">Respuesta:</label>
                  <textarea
                    id="respuesta"
                    value={respuesta}
                    onChange={(event) => setRespuesta(event.target.value)}
                    rows="4"
                    cols="50"
                  />
                </div>
                <div>
                  <label htmlFor="imagen">Imagen:</label>
                  <input
                    type="file"
                    id="imagen"
                    accept=".pdf, image/*, .doc, .docx, .xls, .xlsx"
                    multiple
                    onChange={handleSelectImagen}
                  />
                </div>
                <div>
                  {imagenes.map((imagen, index) => (
                    <div key={imagen.id}>
                      <span>{imagen.file.name}</span>
                      <button
                        type="button"
                        onClick={() => eliminarArchivo(imagen.id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  ))}
                </div>
                <button type="submit">Enviar</button>
              </form>
            </div>
          </div>
        ))}
      </div>
      {esVeterinario && (
        <Button onClick={() => setMostrarModalTratamiento(true)}>
          Agregar Tratamiento
        </Button>
      )}
      <TratamientoMenu
        show={mostrarModalTratamiento}
        onHide={() => setMostrarModalTratamiento(false)}
        consultaId={consultaid}
        onGuardar={() => {
          setMostrarModalTratamiento(false);
        }}
      />
    </Container>
  );
}

export default HiloConsulta;
