import React, { useState, useEffect } from "react";
import { getDatabase, ref as dbRef, child, get } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import { db } from "./firebaseConfig";

function ChatMessage({ message, currentUser }) {
  const { text, uid, timestamp } = message;
  const [userProfileImage, setUserProfileImage] = useState(null);
  const isSentByCurrentUser = uid === currentUser?.uid;

  useEffect(() => {
    const fetchUserProfileImage = async () => {
      try {
        const db = getDatabase();
        const userRef = dbRef(db, `usuario/${uid}`);
        const userSnapshot = await get(userRef);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          if (userData?.imagen_perfil) {
            const storage = getStorage();
            const imagePath = storageRef(storage, userData.imagen_perfil);
            const imageUrl = await getDownloadURL(imagePath);
            setUserProfileImage(imageUrl);
          }
        } else {
          console.error(`Usuario con ID ${uid} no encontrado.`);
        }
      } catch (error) {
        console.error("Error al obtener la imagen de perfil:", error);
      }
    };

    fetchUserProfileImage();
  }, [uid]);

  return (
    <div
      className={`chat-message ${isSentByCurrentUser ? "sent" : "received"}`}
    >
      {!isSentByCurrentUser && userProfileImage && (
        <img className="avatar" src={userProfileImage} alt="User Avatar" />
      )}
      <div className="chat-message-text">
        <p>{text}</p>
      </div>
      {isSentByCurrentUser && userProfileImage && (
        <img className="avatar" src={userProfileImage} alt="User Avatar" />
      )}
    </div>
  );
}

export default ChatMessage;
