import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { getDatabase, ref as dbRef, get } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import CustomNavbar from "./Navbar.js";
import "./css/Glosario.css";

// Inicialización de Firebase
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCItgOCQ9J3oce4_WQGGCntIt90K7A7WQ",
    authDomain: "chanchito-57264.firebaseapp.com",
    databaseURL: "https://chanchito-57264-default-rtdb.firebaseio.com",
    projectId: "chanchito-57264",
    storageBucket: "chanchito-57264.appspot.com",
    messagingSenderId: "297929432202",
    appId: "1:297929432202:web:e0b6e8074001ca4a812173",
    measurementId: "G-VHVL95H5TN",
  });
}

function Chat({
  tituloChat,
  ultimoMensaje,
  cuentaNoLeidos,
  consultaId,
  userId,
  imagenPerfil,
  createdAt,
}) {
  return (
    <Link to={`/chat/${userId}/${consultaId}`} className="burbujaChat">
      <div className="iconoPerfil">
        <img src={imagenPerfil} alt="Perfil" className="imagenPerfil" />
      </div>
      <div>
        <h3>{tituloChat}</h3>
        <p>{ultimoMensaje}</p>
        <p className="fechaMensaje">
          {createdAt
            ? new Date(createdAt.seconds * 1000).toLocaleString()
            : "Fecha no disponible"}
        </p>
      </div>
    </Link>
  );
}

async function agruparChatsPorConsultaId(mensajes, currentUserId) {
  const chats = {};
  for (const mensaje of mensajes) {
    const { consultaId, text, userId, createdAt, leido } = mensaje;
    if (!chats[consultaId]) {
      const otroUser = await obtenerOtroUserId(consultaId, currentUserId);
      chats[consultaId] = {
        tituloChat: otroUser.nombreCompleto,
        ultimoMensaje: text,
        consultaId: consultaId,
        userId: userId,
        imagenPerfil: otroUser.imagenPerfil,
        createdAt: createdAt,
      };
    } else {
      if (createdAt.seconds > chats[consultaId].createdAt.seconds) {
        chats[consultaId].ultimoMensaje = text;
        chats[consultaId].createdAt = createdAt;
      }
      if (!leido) {
        chats[consultaId].cuentaNoLeidos += 1;
      }
    }
  }
  return Object.values(chats);
}

async function obtenerOtroUserId(consultaId, currentUserId) {
  try {
    const messagesSnapshot = await firebase
      .firestore()
      .collection("messages")
      .where("consultaId", "==", consultaId)
      .get();
    const participantes = new Set();
    messagesSnapshot.forEach((doc) => {
      const data = doc.data();
      participantes.add(data.userId);
    });
    participantes.delete(currentUserId);
    const otroUserId = Array.from(participantes)[0];
    if (otroUserId) {
      const datosUsuario = await obtenerDatosUsuario(otroUserId);
      return {
        userId: otroUserId,
        nombreCompleto: datosUsuario.nombre_completo,
        imagenPerfil: datosUsuario.imagen_perfil,
      };
    } else {
      return {
        userId: null,
        nombreCompleto: "Veterinario",
        imagenPerfil: null,
      };
    }
  } catch (error) {
    console.error("Error al obtener el ID y nombre del otro usuario:", error);
    return { userId: null, nombreCompleto: "Veterinario", imagenPerfil: null };
  }
}

async function obtenerDatosUsuario(userId) {
  try {
    console.log(`Obteniendo datos del usuario con ID: ${userId}`);
    const db = getDatabase();
    const userRef = dbRef(db, `usuario/${userId}`);
    const userSnapshot = await get(userRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      if (userData?.imagen_perfil) {
        const storage = getStorage();
        const imagePath = storageRef(storage, userData.imagen_perfil);
        const imageUrl = await getDownloadURL(imagePath);
        return {
          nombre_completo: userData.nombre_completo,
          imagen_perfil: imageUrl,
        };
      } else {
        return {
          nombre_completo: userData.nombre_completo,
          imagen_perfil: null,
        };
      }
    } else {
      console.error(`Usuario con ID ${userId} no encontrado`);
      return { nombre_completo: "Veterinario", imagen_perfil: null };
    }
  } catch (error) {
    console.error("Error al obtener los datos del usuario:", error);
    return { nombre_completo: "Veterinario", imagen_perfil: null };
  }
}

function ListaDeChats() {
  const [user] = useAuthState(firebase.auth());
  const [chats, setChats] = useState([]);

  useEffect(() => {
    if (user) {
      const unsubscribe = firebase
        .firestore()
        .collection("messages")
        .where("userId", "==", user.uid)
        .orderBy("createdAt", "desc")
        .onSnapshot(async (snapshot) => {
          const mensajes = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const chatsAgrupados = await agruparChatsPorConsultaId(
            mensajes,
            user.uid
          );
          setChats(
            chatsAgrupados.sort(
              (a, b) => b.createdAt.seconds - a.createdAt.seconds
            )
          );
        });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <div className="listaChats">
      <CustomNavbar />
      {chats.length > 0 ? (
        chats.map((chat, index) => (
          <Chat
            key={index}
            tituloChat={chat.tituloChat}
            ultimoMensaje={chat.ultimoMensaje}
            consultaId={chat.consultaId}
            userId={chat.userId}
            imagenPerfil={chat.imagenPerfil}
            createdAt={chat.createdAt}
          />
        ))
      ) : (
        <div className="mensajeSinChats">
          Actualmente no posees conversaciones con ningún Veterinario.
        </div>
      )}
    </div>
  );
}

export default ListaDeChats;
