import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  database,
  ref,
  push,
  onValue,
  query,
  equalTo,
  update,
} from "./firebaseConfig";
import { Link } from "react-router-dom";

function TratamientoMenu({
  show,
  onHide,
  consultaId,
  setMostrarModalTratamiento,
  onGuardar,
  setModalVisible,
}) {
  const [tratamiento, setTratamiento] = useState("");
  const [medicamento, setMedicamento] = useState("");
  const [nuevoMedicamento, setNuevoMedicamento] = useState("");
  const [dosis, setDosis] = useState("");
  const [frecuencia, setFrecuencia] = useState("");
  const [medicamentosDisponibles, setMedicamentosDisponibles] = useState([]);
  const [tratamientoExistente, setTratamientoExistente] = useState(null);
  const [showNuevoMedicamentoModal, setShowNuevoMedicamentoModal] =
    useState(false);
  const [usuarioId, setUsuarioId] = useState(null);
  const [fechaTratamiento, setFechaTratamiento] = useState(new Date());

  useEffect(() => {
    const loadMedicamentos = async () => {
      const medicamentosRef = ref(database, "/medicamentos");
      onValue(medicamentosRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const listaMedicamentos = Object.keys(data).map(
            (key) => data[key].nombre
          );
          setMedicamentosDisponibles(listaMedicamentos);
        }
      });
    };

    const loadTratamientoExistente = async () => {
      if (!consultaId) {
        setTratamientoExistente(null);
        return;
      }
      const tratamientosRef = ref(database, "/tratamientos");
      const tratamientosQuery = query(
        tratamientosRef,
        equalTo("consultaId", consultaId)
      );
      onValue(tratamientosQuery, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const tratamiento = Object.values(data)[0];
          setTratamientoExistente(tratamiento);
        } else {
          setTratamientoExistente(null);
        }
      });
    };

    const obtenerUsuarioId = async () => {
      const consultaRef = ref(database, `/consultasencurso/${consultaId}`);
      onValue(consultaRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setUsuarioId(data.usuario_id);
        }
      });
    };

    loadMedicamentos();
    loadTratamientoExistente();
    obtenerUsuarioId();
  }, [consultaId]);

  useEffect(() => {
    if (tratamientoExistente) {
      const { tratamiento, medicamento, dosis, frecuencia } =
        tratamientoExistente;
      setTratamiento(tratamiento);
      setMedicamento(medicamento);
      setDosis(dosis);
      setFrecuencia(frecuencia);
    }
  }, [tratamientoExistente]);

  const handleGuardar = async () => {
    try {
      if (!consultaId) {
        return;
      }
      if (!tratamiento.trim()) {
        alert("El campo tratamiento no puede estar vacío.");
        return;
      }
      if (!(medicamento.trim() || nuevoMedicamento.trim())) {
        alert("Debe seleccionar un medicamento o ingresar uno nuevo.");
        return;
      }
      if (!dosis.trim()) {
        alert("El campo dosis no puede estar vacío.");
        return;
      }
      if (!frecuencia.trim()) {
        alert("El campo frecuencia no puede estar vacío.");
        return;
      }

      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0); // Establece la hora a las 00:00:00 para comparar solo la fecha
      if (fechaTratamiento < hoy) {
        alert(
          "La fecha del tratamiento no puede ser anterior a la fecha de hoy."
        );
        return;
      }

      const tratamientoData = {
        tratamiento: tratamiento,
        medicamento: medicamento || nuevoMedicamento,
        dosis: dosis,
        frecuencia: frecuencia,
        consultaId: consultaId,
        fechaTratamiento: fechaTratamiento
          ? fechaTratamiento.toISOString()
          : null,
      };

      const tratamientoRef = ref(database, "/tratamientos");
      const tratamientoSnapshot = await push(tratamientoRef, tratamientoData);
      const tratamientoId = tratamientoSnapshot.key;
      await update(ref(database, `/consultasencurso/${consultaId}`), {
        tratamiento_id: tratamientoId,
      });

      setTratamiento("");
      setMedicamento("");
      setNuevoMedicamento("");
      setDosis("");
      setFrecuencia("");
      onHide();
      setMostrarModalTratamiento(false);
      onGuardar();
      if (tratamientoExistente) {
        setModalVisible("TratamientoExistente");
      }
    } catch (error) {
      console.error("Error al guardar el tratamiento:", error);
    }
  };

  const handleChat = () => {
    if (usuarioId) {
      const chatLink = `/chat/${usuarioId}/${consultaId}`;
      window.location = chatLink;
    }
  };

  const handleNuevoMedicamento = () => {
    setShowNuevoMedicamentoModal(true);
  };

  const handleCloseNuevoMedicamentoModal = () => {
    setShowNuevoMedicamentoModal(false);
  };

  const handleGuardarNuevoMedicamento = async () => {
    try {
      const medicamentoRef = ref(database, "/medicamentos");
      await push(medicamentoRef, { nombre: nuevoMedicamento });
      handleCloseNuevoMedicamentoModal();
    } catch (error) {
      console.error("Error al guardar el nuevo medicamento:", error);
    }
  };

  const obtenerFechaActual = () => {
    const hoy = new Date();
    const dia = String(hoy.getDate()).padStart(2, "0");
    const mes = String(hoy.getMonth() + 1).padStart(2, "0"); // Enero es 0
    const anio = hoy.getFullYear();
    return `${anio}-${mes}-${dia}`;
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered size="x1">
        <Modal.Header closeButton>
          <Modal.Title>Tratamiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTratamiento">
              <Form.Label>Tratamiento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el tratamiento"
                value={tratamiento}
                onChange={(e) => setTratamiento(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formMedicamento">
              <Form.Label>Medicamento</Form.Label>
              <Form.Control
                as="select"
                value={medicamento}
                onChange={(e) => setMedicamento(e.target.value)}
              >
                <option value="">Seleccione un medicamento</option>
                {medicamentosDisponibles.map((med) => (
                  <option key={med} value={med}>
                    {med}
                  </option>
                ))}
                <option value="nuevo">Agregar uno nuevo</option>
              </Form.Control>
              {medicamento === "nuevo" && (
                <Button variant="link" onClick={handleNuevoMedicamento}>
                  Agregar nuevo medicamento
                </Button>
              )}
            </Form.Group>
            <Form.Group controlId="formDosis">
              <Form.Label>Dosis</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese la dosis"
                value={dosis}
                onChange={(e) => setDosis(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFrecuencia">
              <Form.Label>Frecuencia</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese la frecuencia"
                value={frecuencia}
                onChange={(e) => setFrecuencia(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFechaTratamiento">
              <Form.Label>Fecha de Tratamiento</Form.Label>
              <Form.Control
                type="date"
                value={
                  fechaTratamiento
                    ? fechaTratamiento.toISOString().substring(0, 10)
                    : ""
                }
                onChange={(e) =>
                  setFechaTratamiento(
                    e.target.value ? new Date(e.target.value) : null
                  )
                }
                min={obtenerFechaActual()} // Establece la fecha mínima como la fecha actual
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Link to={`/chat/${consultaId}`}>
            <Button variant="primary" onClick={handleChat}>
              Chat
            </Button>
          </Link>
          <Button variant="secondary" onClick={onHide}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleGuardar}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Agregar medicamento nuevo */}
      <Modal
        show={showNuevoMedicamentoModal}
        onHide={handleCloseNuevoMedicamentoModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Medicamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNuevoMedicamento">
              <Form.Label>Nombre del Medicamento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre del medicamento"
                value={nuevoMedicamento}
                onChange={(e) => setNuevoMedicamento(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseNuevoMedicamentoModal}
          >
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleGuardarNuevoMedicamento}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TratamientoMenu;
