import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import CustomNavbar from "./Navbar";
import ChatMessage from "./ChatMessage";

import "./css/Chat.css";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCItgOCQ9J3oce4_WqQGGCntIt90K7A7WQ",
    authDomain: "chanchito-57264.firebaseapp.com",
    databaseURL: "https://chanchito-57264-default-rtdb.firebaseio.com",
    projectId: "chanchito-57264",
    storageBucket: "chanchito-57264.appspot.com",
    messagingSenderId: "297929432202",
    appId: "1:297929432202:web:e0b6e8074001ca4a812173",
    measurementId: "G-VHVL95H5TN",
  });
}

function Chat() {
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const { usuarioId, consultaId } = useParams();

  const [user] = useAuthState(auth);
  const [messages, setMessages] = useState([]);
  const [formValue, setFormValue] = useState("");
  const [isVeterinario, setIsVeterinario] = useState(false);
  const [veterinarioInfo, setVeterinarioInfo] = useState(null);
  const [veterinarioLoaded, setVeterinarioLoaded] = useState(false);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const checkIfVeterinario = async () => {
      const usuarioDoc = await firestore
        .collection("usuario")
        .doc(user.uid)
        .get();
      const usuarioData = usuarioDoc.data();
      setIsVeterinario(usuarioData?.rol_id === 1);
      setVeterinarioInfo(usuarioData);
      setVeterinarioLoaded(true);
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    if (user) {
      checkIfVeterinario();
    }
  }, [user, firestore]);

  useEffect(() => {
    if (veterinarioLoaded) {
      const messagesRef = firestore.collection("messages");
      const query = messagesRef
        .where("consultaId", "==", consultaId)
        .orderBy("createdAt")
        .limit(25);

      const unsubscribe = query.onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });
        setMessages(data);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      });

      return unsubscribe;
    }
  }, [veterinarioLoaded, firestore, consultaId]);

  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth.currentUser;

    const chatToken = `${consultaId}-${Date.now()}`;

    await firestore.collection("messages").add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
      userId: user.uid,
      consultaId: consultaId,
      chatToken,
    });

    setFormValue("");
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="chat-container">
      {isVeterinario && veterinarioInfo && (
        <div className="mini-navbar">
          <span>{veterinarioInfo.nombre}</span>
          <span>{veterinarioInfo.telefono}</span>
        </div>
      )}
      <CustomNavbar />
      <div className="container">
        <div className="messages">
          {messages.map((msg) => (
            <ChatMessage key={msg.id} message={msg} currentUser={user} />
          ))}
          <div ref={messagesEndRef} />
        </div>
        <form className="paper" onSubmit={sendMessage}>
          <input
            className="text-input"
            value={formValue}
            onChange={(e) => setFormValue(e.target.value)}
            placeholder="Escribe un mensaje..."
          />
          <button className="button" type="submit">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}
export default Chat;
