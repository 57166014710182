import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Card } from "react-bootstrap";
import { database, ref, onValue } from "./firebaseConfig";
import { getDatabase } from "firebase/database";
import CustomNavbar from "./Navbar";
import { getAuth } from "firebase/auth";

function Foro() {
  const [consultas, setConsultas] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userRol, setUserRol] = useState(null);

  useEffect(() => {
    cargarConsultasEnCurso(userId, userRol);
  }, [userId, userRol]);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid);
      const db = getDatabase();
      const userRef = ref(db, `usuario/${user.uid}`);
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setUserRol(userData.rol_id);
          cargarConsultasEnCurso(userData.rol_id, user.uid);
        }
      });
    }
  }, []);

  const cargarConsultasEnCurso = (userId, userRol) => {
    const consultasRef = ref(database, "/consultasencurso");

    onValue(consultasRef, (snapshot) => {
      const consultasData = snapshot.val();

      if (consultasData) {
        let consultasList = Object.entries(consultasData).map(
          ([id, consulta]) => ({
            id,
            pacienteNombre: consulta.paciente_nombre,
            especie: consulta.especie_paciente,
            fechaInicio: new Date(consulta.fecha_inicio).toLocaleDateString(),
            notas: consulta.notas,
            numTratamientos: consulta.tratamientos
              ? Object.keys(consulta.tratamientos).length
              : 0,
            usuarioId: consulta.usuario_id,
          })
        );

        if (userRol !== 1) {
          consultasList = consultasList.filter(
            (consulta) => consulta.usuarioId === userId
          );
        }

        setConsultas(consultasList);
      } else {
        setConsultas([]);
      }
    });
  };

  return (
    <Container>
      <CustomNavbar />
      <h1>Foro - Consultas en Curso</h1>
      <div className="mt-4">
        {consultas.length > 0 ? (
          consultas.map((consulta) => (
            <Card key={consulta.id} className="mb-3">
              <Card.Body>
                <Card.Title>{consulta.pacienteNombre}</Card.Title>
                <Card.Text>
                  <strong>Especie:</strong> {consulta.especie}
                </Card.Text>
                <Card.Text>
                  <strong>Fecha de inicio:</strong> {consulta.fechaInicio}
                </Card.Text>
                <Card.Text>
                  <strong>Notas:</strong> {consulta.notas}
                </Card.Text>
                <Button as={Link} to={`/foro/${consulta.id}`} variant="primary">
                  Ver Foro
                </Button>
              </Card.Body>
            </Card>
          ))
        ) : (
          <div className="mensajeSinForos">
            No posees tratamientos activos para tus consultas.
          </div>
        )}
      </div>
    </Container>
  );
}

export default Foro;
