import React, { useState, useEffect } from "react";
import CustomNavbar from "./Navbar.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { ref, getDatabase, child, get, update } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { auth } from "./firebaseConfig";
import "./css/ProfilePage.css";
import pigLogo from "./img/pig-logo.png";

function ProfilePage() {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [profile, setProfile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [profileImageURL, setProfileImageURL] = useState("");

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!user) return;

      const userId = user.uid;
      const db = getDatabase();
      const userRef = ref(db, `usuario/${userId}`);

      try {
        const snapshot = await get(child(userRef, "/"));
        if (snapshot.exists()) {
          setProfile(snapshot.val());
          setLoading(false);
          setProfileImageURL(snapshot.val().imagen_perfil || "");
        } else {
          throw new Error("No se encontró el perfil del usuario");
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [user]);

  useEffect(() => {
    if (editMode && profile) {
      setNewEmail(profile.email || "");
      setNewPhone(profile.telefono || "");
    }
  }, [editMode, profile]);

  const handleUpdateProfile = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^04\d{9}$/;

      if (newEmail !== "" && !emailRegex.test(newEmail)) {
        throw new Error("El correo electrónico no es válido");
      }

      if (newPhone !== "" && !phoneRegex.test(newPhone)) {
        throw new Error("El número de teléfono no es válido");
      }

      if (user) {
        if (newEmail !== "") {
          await user.updateEmail(newEmail);
          setNewEmail("");
        }

        if (newPassword !== "") {
          const credential = auth.EmailAuthProvider.credential(
            user.email,
            currentPassword
          );
          await user.reauthenticateWithCredential(credential);
          await user.updatePassword(newPassword);
          setCurrentPassword("");
          setNewPassword("");
        }

        const db = getDatabase();
        const userId = user.uid;
        const userRef = ref(db, `usuario/${userId}`);
        await update(userRef, { telefono: newPhone });
      }

      setEditMode(false);
      window.location.reload();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const storage = getStorage();
        const storageRefInstance = storageRef(
          storage,
          `profile_images/${user.uid}/${file.name}`
        );
        await uploadBytes(storageRefInstance, file);
        const imageUrl = await getDownloadURL(storageRefInstance);
        const db = getDatabase();
        const userId = user.uid;
        const userRef = ref(db, `usuario/${userId}`);
        await update(userRef, { imagen_perfil: imageUrl });
        setProfileImageURL(imageUrl);
      } catch (error) {
        setError("Error al cargar la imagen de perfil: " + error.message);
      }
    }
  };

  if (!user) {
    return <div>Inicia sesión para ver tu perfil</div>;
  }

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="profile-container">
      <CustomNavbar />
      <div className="profile-header">
        <h1>Perfil de Usuario</h1>
        {editMode ? (
          <button onClick={handleUpdateProfile}>Guardar</button>
        ) : (
          <button onClick={() => setEditMode(true)} className="botonEditar">
            Editar
          </button>
        )}
      </div>
      <div className="profile-info">
        <div className="profile-picture">
          {profileImageURL ? (
            <img src={profileImageURL} alt="Imagen de Perfil" />
          ) : (
            <img
              src="default-profile-image.jpg"
              alt="Imagen de Perfil por Defecto"
            />
          )}
          {editMode && (
            <div>
              <label>Cargar Imagen de Perfil: </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleProfileImageChange}
              />
            </div>
          )}
        </div>
        <div>
          <label>Nombre Completo: </label>
          <div>{profile?.nombre_completo || ""}</div>
        </div>
        <div>
          <label>Email: </label>
          {editMode ? (
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          ) : (
            <div>{profile?.email || ""}</div>
          )}
        </div>
        <div>
          <label>Teléfono: </label>
          {editMode ? (
            <input
              type="text"
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
            />
          ) : (
            <div>{profile?.telefono || ""}</div>
          )}
        </div>
        {editMode && (
          <div>
            <label>Contraseña actual: </label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
        )}
        {editMode && (
          <div>
            <label>Nueva contraseña: </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfilePage;
