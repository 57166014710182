import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import CustomNavbar from "./Navbar.js";
import "./css/App.css";
import pigLogo from "./img/pig-logo.png";
import chat from "./img/chat.png";
import medicamentos from "./img/medicamentos.png";
import informacion from "./img/informacion.png";
import historial from "./img/historial.png";
import consultas from "./img/consultas.png";

function App() {
  return (
    <div className="App">
      <CustomNavbar />
      <div className="animated-message">
        <p>Una app Veterinaria</p>
      </div>

      {/* Panel de Control */}
      <div className="panel-container">
        <div className="App-panel">
          <div className="App-panel">
            <Button
              className="App-control-button consultas-button"
              variant="outline-secondary"
              as={Link}
              to="/consultas"
            >
              <img src={consultas} alt="Icono de consultas" />
              Consultas
            </Button>
            <Button
              className="App-control-button"
              variant="outline-secondary"
              as={Link}
              to="/glosario"
            >
              <img src={chat} alt="Icono de chat" />
              Chat
            </Button>
            <Button
              className="App-control-button"
              variant="outline-secondary"
              as={Link}
              to="/foro"
            >
              <img src={medicamentos} alt="Icono de medicamentos" />{" "}
              Tratamientos
            </Button>
            <Button
              className="App-control-button"
              variant="outline-secondary"
              as={Link}
              to="/informacion"
            >
              <img src={informacion} alt="Icono de informacion" />
              Informacion
            </Button>
            <Button
              className="App-control-button"
              variant="outline-secondary"
              as={Link}
              to="/historial"
            >
              <img src={historial} alt="Icono de historial" />
              Historial
            </Button>
          </div>
        </div>
      </div>
      <footer className="footer wow fadeIn" data-wow-duration="1s"></footer>
    </div>
  );
}

export default App;
