import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ref, update, database, push, onValue } from "./firebaseConfig";

function EditarTratamientoModal({
  show,
  onHide,
  tratamientoId,
  tratamientoData,
}) {
  const [nuevoTratamiento, setNuevoTratamiento] = useState({
    tratamiento: tratamientoData ? tratamientoData.tratamiento : "",
    medicamento: tratamientoData ? tratamientoData.medicamento : "",
    dosis: tratamientoData ? tratamientoData.dosis : "",
    frecuencia: tratamientoData ? tratamientoData.frecuencia : "",
  });

  const [medicamentosDisponibles, setMedicamentosDisponibles] = useState([]);
  const [showNuevoMedicamentoModal, setShowNuevoMedicamentoModal] =
    useState(false);
  const [nuevoMedicamento, setNuevoMedicamento] = useState("");

  useEffect(() => {
    const loadMedicamentos = async () => {
      const medicamentosRef = ref(database, "/medicamentos");
      onValue(medicamentosRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const listaMedicamentos = Object.keys(data).map(
            (key) => data[key].nombre
          );
          setMedicamentosDisponibles(listaMedicamentos);
        }
      });
    };

    loadMedicamentos();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNuevoTratamiento({
      ...nuevoTratamiento,
      [name]: value,
    });
  };

  const handleGuardarCambios = async () => {
    try {
      const tratamientoRef = ref(database, `/tratamientos/${tratamientoId}`);
      await update(tratamientoRef, nuevoTratamiento);
    } catch (error) {}
    onHide();
  };

  const handleNuevoMedicamento = () => {
    setShowNuevoMedicamentoModal(true);
  };

  const handleCloseNuevoMedicamentoModal = () => {
    setShowNuevoMedicamentoModal(false);
  };

  const handleGuardarNuevoMedicamento = async () => {
    try {
      const medicamentoRef = ref(database, "/medicamentos");
      await push(medicamentoRef, { nombre: nuevoMedicamento });
      handleCloseNuevoMedicamentoModal();
    } catch (error) {}
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Tratamiento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTratamiento">
            <Form.Label>Tratamiento</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el tratamiento"
              name="tratamiento"
              value={nuevoTratamiento.tratamiento}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formMedicamento">
            <Form.Label>Medicamento</Form.Label>
            <Form.Control
              as="select"
              value={nuevoTratamiento.medicamento}
              onChange={handleChange}
              name="medicamento"
            >
              <option value="">Seleccione un medicamento</option>
              {medicamentosDisponibles.map((med) => (
                <option key={med} value={med}>
                  {med}
                </option>
              ))}
              <option value="nuevo">Agregar uno nuevo</option>
            </Form.Control>
            {nuevoTratamiento.medicamento === "nuevo" && (
              <Button variant="link" onClick={handleNuevoMedicamento}>
                Agregar nuevo medicamento
              </Button>
            )}
          </Form.Group>
          <Form.Group controlId="formDosis">
            <Form.Label>Dosis</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese la dosis"
              name="dosis"
              value={nuevoTratamiento.dosis}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formFrecuencia">
            <Form.Label>Frecuencia</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese la frecuencia"
              name="frecuencia"
              value={nuevoTratamiento.frecuencia}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleGuardarCambios}>
          Guardar Cambios
        </Button>
      </Modal.Footer>
      <Modal
        show={showNuevoMedicamentoModal}
        onHide={handleCloseNuevoMedicamentoModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Medicamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNuevoMedicamento">
              <Form.Label>Nombre del Medicamento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre del medicamento"
                value={nuevoMedicamento}
                onChange={(e) => setNuevoMedicamento(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseNuevoMedicamentoModal}
          >
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleGuardarNuevoMedicamento}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </Modal>
  );
}

export default EditarTratamientoModal;
