import React, { useState } from "react";
import "./css/RegisterPage.css";
import pigLogo from "./img/pig-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { firebaseApp, database, ref, set } from "./firebaseConfig";

function RegisterPage() {
  const [error, setError] = useState(null);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [telefono, setTelefono] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [genero, setGenero] = useState("");
  const [emailError, setEmailError] = useState("");
  const [telefonoError, setTelefonoError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const auth = getAuth(firebaseApp);

  const handlePhoneNumberInput = (event) => {
    const input = event.target;
    input.value = input.value.replace(/\D/g, "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        setEmailError("");
        break;
      case "password":
        setPassword(value);
        setPasswordError("");
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        setConfirmPasswordError("");
        break;
      case "telefono":
        setTelefono(value);
        setTelefonoError("");
        break;
      case "nombre":
        setNombre(value);
        break;
      case "apellido":
        setApellido(value);
        break;
      case "nombreUsuario":
        setNombreUsuario(value);
        break;
      case "genero":
        setGenero(value);
        break;
      default:
        break;
    }
    setError(null);
  };

  const handleRegisterClick = async (event) => {
    event.preventDefault();
    setError(null);
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setTelefonoError("");
    const nombreCompleto = `${nombre} ${apellido}`;
    let isValid = true;
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (
      !nombre ||
      !apellido ||
      !email ||
      !password ||
      !confirmPassword ||
      !telefono
    ) {
      setError("Todos los campos son obligatorios.");
      isValid = false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Por favor, introduce un correo electrónico válido.");
      isValid = false;
    }
    if (!/^04\d{9}$/.test(telefono)) {
      setTelefonoError(
        "El número de teléfono debe tener 11 dígitos y comenzar con 04."
      );
      isValid = false;
    }
    if (password.length < 6 || password.length > 20) {
      setPasswordError("La contraseña debe tener entre 6 y 20 caracteres.");
      isValid = false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Las contraseñas no coinciden.");
      isValid = false;
    }
    if (!isValid) {
      setIsSubmitting(false);
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await sendEmailVerification(user);
      await updateProfile(user, {
        displayName: nombreCompleto,
      });
      await set(ref(database, "usuario/" + user.uid), {
        nombre_completo: nombreCompleto,
        email: email,
        telefono: telefono,
        genero: genero,
        nombre_usuario: nombreUsuario,
        rol_id: 0,
      });
      setSuccessMessage("Usuario registrado con éxito");
      alert("Registro exitoso, por favor verifica tu correo electrónico.");
      navigate("/");
    } catch (error) {
      setErrorMessage("Error en el registro: " + error.message);
      setError(error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div
        className="login-container"
        style={{
          position: "relative",
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
        }}
      >
        <img src={pigLogo} alt="Logo de cerdito" className="logo" />
        <form
          className="register-form-container"
          onSubmit={handleRegisterClick}
        >
          <label htmlFor="username">Formulario de Registro</label>
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            className="register-rounded-input"
            placeholder="Correo electrónico"
          />
          {emailError && <div className="error-message">{emailError}</div>}
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handleInputChange}
            className="register-rounded-input"
            placeholder="Contraseña"
          />
          {passwordError && (
            <div className="error-message">{passwordError}</div>
          )}
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleInputChange}
            className="register-rounded-input"
            placeholder="Confirmar contraseña"
          />
          {confirmPasswordError && (
            <div className="error-message">{confirmPasswordError}</div>
          )}
          <input
            type="text"
            id="telefono"
            name="telefono"
            value={telefono}
            onChange={handleInputChange}
            onInput={handlePhoneNumberInput}
            className="register-rounded-input"
            placeholder="Teléfono"
            required
          />
          {telefonoError && (
            <div className="error-message">{telefonoError}</div>
          )}
          <input
            type="text"
            id="register-nombre"
            name="nombre"
            className="register-rounded-input"
            placeholder="Nombre"
            value={nombre}
            onChange={handleInputChange}
          />
          <div></div>
          <input
            type="text"
            id="register-apellido"
            name="apellido"
            className="register-rounded-input"
            placeholder="Apellido"
            value={apellido}
            onChange={handleInputChange}
          />
          <div></div>
          <select
            id="genero"
            name="genero"
            value={genero}
            onChange={handleInputChange}
            className="register-rounded-input"
          >
            <option value="">Selecciona tu género</option>
            <option value="male">Masculino</option>
            <option value="female">Femenino</option>
            <option value="other">Otro</option>
          </select>
          <div className="button-container">
            <button
              type="submit"
              className="login-button"
              disabled={isSubmitting}
            >
              Crear cuenta
            </button>
            <Nav.Link as={Link} to="/" className="login-link">
              ¿Ya tienes cuenta? Inicia sesión aquí
            </Nav.Link>
            <div className={`error-message ${error && "show"}`}>{error}</div>
          </div>
        </form>
        {successMessage && <div>{successMessage}</div>}
      </div>
    </>
  );
}

export default RegisterPage;
