import React, { useState, useEffect } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import { database, ref, push, remove, update, get } from "./firebaseConfig";
import { useParams, Link } from "react-router-dom";
import { getDatabase } from "firebase/database";
import CustomNavbar from "./Navbar";

function Facturacion() {
  const [medicamentos, setMedicamentos] = useState("");
  const { consultaId } = useParams();
  const [procedimientos, setProcedimientos] = useState("");
  const [costo, setCosto] = useState("");
  const [mostrarFactura, setMostrarFactura] = useState(false);
  const [medicamentoSeleccionado, setMedicamentoSeleccionado] = useState([""]);
  const [medicamentosDisponibles, setMedicamentosDisponibles] = useState([]);
  const [nuevoMedicamento, setNuevoMedicamento] = useState("");
  const [showNuevoMedicamentoModal, setShowNuevoMedicamentoModal] =
    useState(false);
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [emailUsuario, setEmailUsuario] = useState("");
  const [telefonoUsuario, setTelefonoUsuario] = useState("");
  const [fechaInicioConsulta, setFechaInicioConsulta] = useState("");
  const [fechaFinConsulta, setFechaFinConsulta] = useState(null);
  const [nombrePaciente, setNombrePaciente] = useState("");
  const [nombreVeterinario, setNombreVeterinario] = useState("");

  useEffect(() => {
    const db = firebase.database();
    const medicamentosRef = db.ref("medicamentos");
    medicamentosRef.on("value", (snapshot) => {
      const meds = snapshot.val();
      const medicamentosLista = Object.keys(meds).map(
        (key) => meds[key].nombre
      );
      setMedicamentosDisponibles(medicamentosLista);
    });
  }, []);

  useEffect(() => {
    if (!consultaId) {
      return;
    }
    const fetchConsultaData = async () => {
      try {
        const db = firebase.database();
        const consultaRef = db.ref(`consultasencurso/${consultaId}`);
        const consultaSnapshot = await consultaRef.get();
        if (!consultaSnapshot.exists()) {
          return;
        }
        const consultaData = consultaSnapshot.val();
        setNombreUsuario(consultaData.usuario_nombre_completo);
        setFechaInicioConsulta(consultaData.fecha_inicio);
        setFechaFinConsulta(consultaData.fechaFinConsulta);
        setNombrePaciente(consultaData.paciente_nombre);

        if (consultaData.veterinario_id) {
          const veterinarioRef = db.ref(
            `usuario/${consultaData.veterinario_id}`
          );
          const veterinarioSnapshot = await veterinarioRef.get();
          if (veterinarioSnapshot.exists()) {
            const veterinarioData = veterinarioSnapshot.val();
            setNombreVeterinario(veterinarioData.nombre_completo);
          }
        }

        if (consultaData.usuario_id) {
          const usuarioRef = db.ref(`usuario/${consultaData.usuario_id}`);
          const usuarioSnapshot = await usuarioRef.get();
          if (usuarioSnapshot.exists()) {
            const usuarioData = usuarioSnapshot.val();
            setEmailUsuario(usuarioData.email);
            setTelefonoUsuario(usuarioData.telefono);
          }
        }
      } catch (error) {}
    };
    fetchConsultaData();
  }, [consultaId]);

  const handleMedicamentoChange = (event, index) => {
    const newMedicamentos = [...medicamentoSeleccionado];
    newMedicamentos[index] = event.target.value;
    setMedicamentoSeleccionado(newMedicamentos);

    if (event.target.value === "Agregar Nuevo Medicamento") {
      handleNuevoMedicamento();
    }
  };

  const handleRemoveMedicamento = (index) => {
    const newMedicamentos = [...medicamentoSeleccionado];
    newMedicamentos.splice(index, 1);
    setMedicamentoSeleccionado(newMedicamentos);
  };
  const handleAddMedicamento = () => {
    setMedicamentoSeleccionado([...medicamentoSeleccionado, ""]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setMedicamentos(medicamentoSeleccionado.join(", "));
    setMostrarFactura(true);
  };

  const handleNuevoMedicamento = () => {
    setShowNuevoMedicamentoModal(true);
  };

  const handleCloseNuevoMedicamentoModal = () => {
    setShowNuevoMedicamentoModal(false);
  };

  const finalizarConsulta = async (consultaId) => {
    if (typeof consultaId === "object" && consultaId !== null) {
      consultaId = consultaId.id;
    }
    const db = getDatabase();
    const fechaFin = new Date();

    const consultaEnCursoRef = ref(db, `consultasencurso/${consultaId}`);
    const consultaSnapshot = await get(consultaEnCursoRef);

    if (!consultaSnapshot.exists()) {
      return;
    }

    const consultaData = consultaSnapshot.val();
    const { usuario_id, notas, solicitud_id } = consultaData;

    const solicitudRef = ref(db, `solicitudesconsulta/${solicitud_id}`);
    const solicitudSnapshot = await get(solicitudRef);
    const solicitudData = solicitudSnapshot.val();
    const { paciente_id } = solicitudData;
    await update(solicitudRef, { estado: "finalizado" });
    await remove(consultaEnCursoRef);
    const historialConsultasRef = ref(db, "historialconsultas");
    const nuevaHistoria = {
      consultaId,
      veterinario: nombreVeterinario,
      estado: "finalizado",
      fechaFin: fechaFin.toISOString(),
      fechaInicioConsulta: consultaData.fecha_inicio,
      notas,
      paciente_id,
      solicitud_id,
      usuario_id,
    };
    const nuevaHistoriaRef = await push(historialConsultasRef, nuevaHistoria);
    const historiaId = nuevaHistoriaRef.key;

    let medicamentosFinal = medicamentos.trim() === "" ? "N/A" : medicamentos;
    let costoFinal = costo.trim() === "" ? "N/A" : costo;

    const facturacionRef = ref(db, "facturacion");
    const nuevaFactura = {
      veterinario: nombreVeterinario,
      usuario: nombreUsuario,
      email: emailUsuario,
      telefono: telefonoUsuario,
      fechaInicioConsulta: fechaInicioConsulta,
      fechaFin: fechaFin.toISOString(),
      paciente: nombrePaciente,
      procedimientos,
      medicamentosFinal,
      costoFinal,
      historiaId,
    };
    const nuevaFacturaRef = await push(facturacionRef, nuevaFactura);
    const facturaId = nuevaFacturaRef.key;

    await update(nuevaHistoriaRef, { facturaId });

    setFechaFinConsulta(fechaFin);
    window.location.href = `/historial`;
  };

  const handleGuardarNuevoMedicamento = async () => {
    try {
      const medicamentoRef = ref(database, "/medicamentos");
      await push(medicamentoRef, { nombre: nuevoMedicamento });
      handleCloseNuevoMedicamentoModal();
    } catch (error) {}
  };

  return (
    <Card className="mb-3">
      <CustomNavbar />
      <Card.Body>
        <Card.Title>
          <h1>Menú de Facturas</h1>
        </Card.Title>
        <Form onSubmit={handleSubmit}>
          {medicamentoSeleccionado.map((medicamento, index) => (
            <Form.Group controlId={`medicamentos-${index}`} key={index}>
              <Form.Label>Medicamentos</Form.Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  as="select"
                  value={medicamento}
                  onChange={(e) => handleMedicamentoChange(e, index)}
                >
                  <option value="">Seleccione un medicamento</option>
                  {medicamentosDisponibles.map((med) => (
                    <option key={med} value={med}>
                      {med}
                    </option>
                  ))}
                  <option value="Agregar Nuevo Medicamento">
                    Agregar Nuevo Medicamento
                  </option>
                </Form.Control>
                {index > 0 && (
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveMedicamento(index)}
                  >
                    -
                  </Button>
                )}
              </div>
            </Form.Group>
          ))}
          <Button variant="primary" onClick={handleAddMedicamento}>
            +
          </Button>
          <Form.Group controlId="procedimientos">
            <Form.Label>Procedimientos</Form.Label>
            <Form.Control
              type="text"
              value={procedimientos}
              onChange={(e) => setProcedimientos(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="costo">
            <Form.Label>Costo</Form.Label>
            <Form.Control
              type="number"
              value={costo}
              onChange={(e) => setCosto(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Generar Factura
          </Button>
        </Form>
        {mostrarFactura && (
          <div>
            <h2>Factura</h2>
            <p>Veterinario Asignado: {nombreVeterinario}</p>
            <p>Nombre del usuario: {nombreUsuario}</p>
            <p>Email del usuario: {emailUsuario}</p>
            <p>Teléfono del usuario: {telefonoUsuario}</p>
            <p>
              Fecha de inicio de la consulta:{" "}
              {fechaInicioConsulta && fechaInicioConsulta.toLocaleString()}
            </p>
            <p>
              Fecha de fin de la consulta:{" "}
              {fechaFinConsulta && fechaFinConsulta.toLocaleString()}
            </p>
            <p>Nombre del paciente: {nombrePaciente}</p>
            <p>Medicamentos: {medicamentos}</p>
            <p>Procedimientos: {procedimientos}</p>
            <p>Costo: {costo}</p>
            <Button
              variant="success"
              onClick={() => finalizarConsulta(consultaId)}
            >
              Finalizar Consulta
            </Button>
            <Link to="/consultas"></Link>
          </div>
        )}
        <Modal
          show={showNuevoMedicamentoModal}
          onHide={handleCloseNuevoMedicamentoModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Agregar Nuevo Medicamento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formNuevoMedicamento">
                <Form.Label>Nombre del Medicamento</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese el nombre del medicamento"
                  value={nuevoMedicamento}
                  onChange={(e) => setNuevoMedicamento(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseNuevoMedicamentoModal}
            >
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleGuardarNuevoMedicamento}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </Card>
  );
}

export default Facturacion;
