import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, get, child } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Navbar from "./Navbar";
import "./css/Historial.css";

function Historial() {
  const [historial, setHistorial] = useState([]);
  const [filtro, setFiltro] = useState("Todos");
  const [facturaData, setFacturaData] = useState(null);
  const [usuarioData, setUsuarioData] = useState(null);
  const [pacienteData, setPacienteData] = useState(null);
  const [consulta, setConsulta] = useState(null);

  useEffect(() => {
    const db = getDatabase();
    const historialRef = ref(db, "historialconsultas");
    const userRef = ref(db, "usuario");

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        onValue(userRef, (snapshot) => {
          const users = snapshot.val();
          const currentUser = users[uid];
          if (currentUser.rol_id === 0) {
            onValue(historialRef, (snapshot) => {
              const data = snapshot.val();
              if (data) {
                const sortedData = Object.entries(data)
                  .map(([id, consulta]) => ({
                    id,
                    facturaId: consulta.facturaId,
                    ...consulta,
                  }))
                  .filter((consulta) => consulta.usuario_id === uid)
                  .sort((a, b) => {
                    const order = [
                      "en curso",
                      "rechazada",
                      "cancelada",
                      "finalizado",
                    ];
                    return order.indexOf(a.estado) - order.indexOf(b.estado);
                  });

                setHistorial(sortedData);
              }
            });
          }
        });
      }
    });
  }, []);

  async function getFacturaData(facturaId) {
    if (!facturaId) {
      return null;
    }

    const db = getDatabase();

    if (!db) {
      return null;
    }

    const facturaRef = ref(db, `facturacion/${facturaId}`);
    if (!facturaRef) {
      return null;
    }

    let facturaData = null;

    try {
      const snapshot = await get(facturaRef);
      if (snapshot.exists()) {
        facturaData = snapshot.val();
      } else {
      }
    } catch (error) {}

    return facturaData;
  }

  async function getUsuarioData(usuarioId) {
    if (!usuarioId) {
      return null;
    }

    const db = getDatabase();

    if (!db) {
      return null;
    }

    const usuarioRef = ref(db, `usuario/${usuarioId}`);
    if (!usuarioRef) {
      return null;
    }

    let usuarioData = null;

    try {
      const snapshot = await get(usuarioRef);
      if (snapshot.exists()) {
        usuarioData = snapshot.val();
      } else {
      }
    } catch (error) {}

    return usuarioData;
  }

  async function getPacienteData(pacienteId) {
    if (!pacienteId) {
      return null;
    }

    const db = getDatabase();

    if (!db) {
      return null;
    }

    const pacienteRef = ref(db, `paciente/${pacienteId}`);
    if (!pacienteRef) {
      return null;
    }

    let pacienteData = null;

    try {
      const snapshot = await get(pacienteRef);
      if (snapshot.exists()) {
        pacienteData = snapshot.val();
      } else {
      }
    } catch (error) {}

    return pacienteData;
  }

  useEffect(() => {
    const fetchUserDataAndPacienteData = async () => {
      if (consulta) {
        const usuarioData = await getUsuarioData(consulta.usuario_id);
        const pacienteData = await getPacienteData(consulta.paciente_id);
        setUsuarioData(usuarioData);
        setPacienteData(pacienteData);
      }
    };

    fetchUserDataAndPacienteData();
  }, [consulta]);

  useEffect(() => {
    const fetchFacturaData = async () => {
      const newFacturaData = {};
      for (const consulta of historial) {
        newFacturaData[consulta.facturaId] = await getFacturaData(
          consulta.facturaId
        );
      }
      setFacturaData(newFacturaData);
    };

    fetchFacturaData();
  }, [historial]);

  useEffect(() => {
    const db = getDatabase();
    const historialRef = ref(db, "historialconsultas");

    onValue(historialRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const sortedData = Object.entries(data)
          .map(([id, consulta]) => ({ id, ...consulta }))
          .sort((a, b) => {
            const order = ["en curso", "rechazada", "cancelada", "finalizado"];
            return order.indexOf(a.estado) - order.indexOf(b.estado);
          });

        setHistorial(sortedData);
        setConsulta(sortedData[0]);
      }
    });
  }, []);

  const filtrarHistorial = (estado) => {
    setFiltro(estado);
  };

  const historialFiltrado = historial.filter(
    (consulta) => filtro === "Todos" || consulta.estado === filtro
  );

  const historialAgrupado = historialFiltrado.reduce((acc, consulta) => {
    (acc[consulta.estado] = acc[consulta.estado] || []).push(consulta);
    return acc;
  }, {});

  const renderRechazada = () => {
    if (!usuarioData || !pacienteData) {
      return null;
    }

    return (
      <>
        <p>
          <p>
            Nombre del Usuario:{" "}
            {usuarioData
              ? usuarioData.nombre_completo
              : "Usuario no encontrado"}
          </p>
          <p>
            Nombre del Paciente:{" "}
            {pacienteData ? pacienteData.nombre : "Paciente no encontrado"}
          </p>
          <p>Notas: {consulta.notas}</p>
          <p>Motivo de Rechazo: {consulta.motivoRechazo}</p>
        </p>
        Fecha de Rechazo: {new Date(consulta.fechaRechazo).toLocaleString()}
      </>
    );
  };

  const renderFinalizado = (data, facturaId) => {
    const factura = facturaData[facturaId];
    return (
      <>
        <p>Veterinario Asignado: {factura?.veterinario || "No asignado"}</p>
        <p>Nombre del Usuario: {factura?.usuario || "N/A"}</p>
        <p>Nombre del Paciente: {factura?.paciente || "N/A"}</p>
        <p>
          Fecha Inicio:{" "}
          {factura?.fechaInicioConsulta
            ? new Date(factura.fechaInicioConsulta).toLocaleString()
            : "N/A"}
        </p>
        <p>
          Fecha Fin:{" "}
          {factura?.fechaFin
            ? new Date(factura.fechaFin).toLocaleString()
            : "N/A"}
        </p>
        <p>Medicamentos: {factura?.medicamentos || "N/A"}</p>
        <p>Procedimientos: {factura?.procedimientos || "N/A"}</p>
        <p>Costo: {factura?.costo || "N/A"}</p>
      </>
    );
  };

  const renderDefault = (consulta) => (
    <>
      <p>Veterinario Asignado: {facturaData.veterinario || "No asignado"}</p>
      <p>Nombre del Usuario: {consulta.usuario_nombre_completo}</p>
      <p>Nombre del Paciente: {consulta.nombrePaciente}</p>
      <p>Especie del Paciente: {consulta.especiePaciente}</p>
      <p>Fecha: {new Date(consulta.fecha).toLocaleString()}</p>
      <p>Motivo: {consulta.motivo}</p>
    </>
  );

  return (
    <div>
      <Navbar />
      <h1>Historial de Consultas</h1>
      <div className="Buttones">
        <button onClick={() => filtrarHistorial("Todos")}>Todos</button>
        <button onClick={() => filtrarHistorial("finalizado")}>
          Finalizadas
        </button>
        <button onClick={() => filtrarHistorial("rechazada")}>
          Rechazadas
        </button>
        <button onClick={() => filtrarHistorial("cancelada")}>
          Canceladas
        </button>
      </div>
      {Object.entries(historialAgrupado).map(([estado, consultas]) => (
        <div key={estado}>
          <h2>{estado}</h2>
          {consultas.map((consulta) => {
            const facturaId = consulta.facturaId;
            const data = facturaData[facturaId];
            return (
              <div key={consulta.id} className="consulta-card">
                {estado === "rechazada"
                  ? renderRechazada(consulta)
                  : estado === "finalizado"
                  ? renderFinalizado(data, facturaId)
                  : renderDefault(consulta)}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

export default Historial;
